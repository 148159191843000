import {Subject} from 'rxjs';

export class IllustrationService {

    illustrationSubject = new Subject<any[]>();
    private illustrations = [
        {
            id: 0,
            src: "reveThys.jpg",
            caption: "Le r\xeave de Thys (1e ex-eaquo concours de SA William, Le R\xeave de Thys)"
        },
        {
            id: 1,
            src: "alizee.jpg",
            caption: "Aliz\xe9e (Projet Epine - OC)"
        },
        {
            id: 2,
            src: "carolinaYoung.jpg",
            caption: "Carolina enfant (Projet Epine - OC)"
        },
        {
            id: 3,
            src: "henriettine.jpg",
            caption: "Henriettine (Projet Epine - OC)"
        },
        {
            id: 4,
            src: "christo.jpg",
            caption: "De retour \xe0 la campagne (Christo Artworks - OC)"
        },
        {
            id: 5,
            src: "carolinaAdult.jpg",
            caption: "Carolina adulte (Projet Epine - OC)"
        },
        {
            id: 6,
            src: "carolinaWake.jpg",
            caption: "Carolina au r\xe9veil (Projet Epine - OC)"
        },
        {
            id: 7,
            src: "fridaGrete.jpg",
            caption: "Frida & Grete (Projet Epine - OC)"
        },
        {
            id: 8,
            src: "kalnida.jpg",
            caption: "Protagonistes de Kalnida (Projet MNG - OC)"
        },
        {
            id: 9,
            src: "carolinaBath.jpg",
            caption: "Le bain de Carolina (Projet Epine - OC)"
        },
        {
            id: 10,
            src: "wedding.jpg",
            caption: "Mariage champ\xeatre (OC)"
        },
        {
            id: 11,
            src: "sielaOja.jpg",
            caption: "Protagonistes de Sie'La Oja (Projet MNG - OC)"
        },
        {
            id: 12,
            src: "kiki.jpg",
            caption: "Kiki la petite sorci\xe8re (Kiki's Delivery Service)"
        },
        {
            id: 13,
            src: "royauxKalnida.jpg",
            caption: "La royaut\xe9 de Kalnida (Projet MNG - OC)"
        },
        {
            id: 14,
            src: "cleo.jpg",
            caption: "Cleo (Les entrechats)"
        },
        {
            id: 15,
            src: "tristKalnida.jpg",
            caption: "Tristesse dans le blizzard (Projet MNG - OC)",
            position: "right"
        },
        {
            id: 16,
            src: 'rebirth.jpg',
            caption: "Clotilde & Nostra (Mascottes de l'équipe Яe-Birth (MK8DX) - OC)",
            position: "right"
        }
    ];

    emitillustrationSubject() {
        this.illustrationSubject.next(this.illustrations.slice());
    }

    getillustrationById (id: number) {
        const illustration = this.illustrations.find(
            (illustrationObject) => {
                return illustrationObject.id === id;
            }
        );
        return illustration;
    }

}
