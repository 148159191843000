import {Subject} from 'rxjs';


export class RealisationService {

    realisationSubject = new Subject<any[]>();
    private realisations = [
        {
            id: 0,
            title: 'Portfolio',
            type: 'personnel',
            description: "Différentes versions de mon portfolio.",
            poste: 'UI designer / Dév. front-end',
            duree: 'Juin 2011 - Maintenant',
            link: '',
            outil: 'Visual Studio Code, Photoshop CC',
            language: 'HTML-CSS, SASS, Javascript, Bootstrap 4, Angular 7',
            accueil: 'assets/img/realisation/projets/portfolio/christo.jpg',
            line: 'horizontal',
            maquette:  [
                {   img: 'assets/img/realisation/projets/portfolio/christo1.jpg',
                    name: "Page d'accueil v1 (2011)"
                },
                {   img: 'assets/img/realisation/projets/portfolio/christo2.jpg',
                name: "Page d'illustrations v1"
                },
                {   img: 'assets/img/realisation/projets/portfolio/christo3.jpg',
                name: "Page d'accueil v2 (2013)"
                },
                {   img: 'assets/img/realisation/projets/portfolio/christo4.jpg',
                name: "Page des réalisations v2"
                },
                {   img: 'assets/img/realisation/projets/portfolio/christo5.jpg',
                name: "Page d'accueil v3 (2015)"
                },
                {   img: 'assets/img/realisation/projets/portfolio/christo6.jpg',
                name: "Page des réalisations v3"
                }
            ]
        },
        {
            id: 1,
            title: 'Syndicat Mixte du Salève',
            type: 'professionnel',
            description: "Refonte du site du Syndicat Mixte du Salève. Site réalisé sous Joomla! en respectant la charte graphique du Syndicat.",
            poste: 'UI designer / Dév. web',
            duree: 'Septembre - Novembre 2014',
            link: 'http://www.syndicat-mixte-du-saleve.fr',
            outil: 'Notepad ++, Photoshop CS6',
            language: 'HTML-CSS, Javascript, Joomla 3',
            accueil: 'assets/img/realisation/projets/sms/sms.jpg',
            line: 'horizontal',
            maquette:  [
                {   img: 'assets/img/realisation/projets/sms/sms1.jpg',
                    name: "Page d'accueil"
                },
                {   img: 'assets/img/realisation/projets/sms/sms2.jpg',
                name: "Présentation des communes"
                }
            ]
        },
        {
            id: 2,
            title: 'Blabla.Academy',
            type: 'professionnel',
            description: "Projet d’étude en équipe de 16 personnes. Réalisation d’une plate forme web complète gamifiée d’un MOOC (cours en ligne ouvert et massif), spécialisée dans l’immobilier.",
            poste: 'UI designer / Dév. web',
            duree: 'Septembre 2014 - Juin 2015',
            link: '',
            outil: 'PHP Storm, Photoshop CS6',
            language: 'HTML-CSS, Sass, Javascript, Twig',
            accueil: 'assets/img/realisation/projets/blabla/blabla.jpg',
            line: 'horizontal',
            maquette:  [
                {   img: 'assets/img/realisation/projets/blabla/blabla-1.jpg',
                    name: "Page d'accueil"
                },
                {   img: 'assets/img/realisation/projets/blabla/blabla-2.jpg',
                name: "Listing des annonces immibilières"
                },
                {   img: 'assets/img/realisation/projets/blabla/blabla-3.jpg',
                name: "Consultation des fiches d'expert"
                }
            ]
        },
        {
            id: 3,
            title: 'Lise Charmel',
            type: 'professionnel',
            description: "Refonte du site internet Lise Charmel – fabricant et distributeur français de plusieurs marques de lingerie et de maillots de bain de luxe.",
            poste: 'UX-UI designer / Dév. web (Alternance)',
            duree: 'Novembre 2012 - Septembre 2015',
            link: 'http://www.lisecharmel.com',
            outil: 'Sublime Text 2, Isotools Studio, Photoshop CS6',
            language: 'HTML-CSS, Javascript, Jquery',
            accueil: 'assets/img/realisation/projets/lc/lisecharmel.jpg',
            line: 'horizontal',
            maquette:  [
                {   img: 'assets/img/realisation/projets/lc/lisecharmel-1.jpg',
                    name: "Page d'accueil"
                },
                {   img: 'assets/img/realisation/projets/lc/lisecharmel-2.jpg',
                name: "Listing des collections"
                },
                {   img: 'assets/img/realisation/projets/lc/lisecharmel-3.jpg',
                name: "Fiche d'une collection"
                },
                {   img: 'assets/img/realisation/projets/lc/lisecharmel-4.jpg',
                name: "Affichage des articles"
                }
            ]
        },
        {
            id: 4,
            title: 'Hospices Civils de Lyon',
            type: 'professionnel',
            description: "Réponse d'un appel d'offre sur la refonte de leur site internet",
            poste: 'UI designer',
            duree: 'Fevrier 2016',
            link: '',
            outil: 'Photoshop CC, Invision',
            language: '',
            accueil: 'assets/img/realisation/projets/hcl/hcl.jpg',
            line: 'vertical',
            maquette:  [
                {   img: 'assets/img/realisation/projets/hcl/hcl-1.jpg',
                    name: "Page d'accueil"
                },
                {   img: 'assets/img/realisation/projets/hcl/hcl-2.jpg',
                name: "Espace patient"
                },
                {   img: 'assets/img/realisation/projets/hcl/hcl-3.jpg',
                name: "Fiche traitement"
                }
            ]
        },
        {
            id: 5,
            title: 'GRTgaz',
            type: 'professionnel',
            description: "Conception graphique d’un POC concernant un portail web de gestion des temps et des actualités.",
            poste: 'UI designer',
            duree: 'Avril 2016',
            link: '',
            outil: 'Photoshop CC, Invision',
            language: '',
            accueil: 'assets/img/realisation/projets/grtgaz/grtgaz.jpg',
            line: 'horizontal',
            maquette:  [
                {   img: 'assets/img/realisation/projets/grtgaz/grtgaz-1.jpg',
                    name: "Page de connexion"
                },
                {   img: 'assets/img/realisation/projets/grtgaz/grtgaz-2.jpg',
                name: "Page d'absences"
                },
                {   img: 'assets/img/realisation/projets/grtgaz/grtgaz-3.jpg',
                name: "Traitement d'une demande d'absence"
                }
            ]
        },
        {
            id: 6,
            title: 'Euroradio',
            type: 'professionnel',
            description: "Conception graphique d’une plateforme d’échange musical.",
            poste: 'UI designer',
            duree: 'Mai - Septembre 2016',
            link: 'https://www.ebu.ch/radio',
            outil: 'Photoshop CC',
            language: '',
            accueil: 'assets/img/realisation/projets/euroradio/eurovision.jpg',
            line: 'vertical',
            maquette:  [
                {   img: 'assets/img/realisation/projets/euroradio/eurovision-1.jpg',
                    name: "Dashboard"
                },
                {   img: 'assets/img/realisation/projets/euroradio/eurovision-2.jpg',
                name: "Formulaire de création d'offre d'enregistrement"
                },
                {   img: 'assets/img/realisation/projets/euroradio/eurovision-3.jpg',
                name: "Listing des offres"
                }
            ]
        },
        {
            id: 7,
            title: 'Pays du Vuache',
            type: 'professionnel',
            description: "Refonte du site du Pays du Vuache",
            poste: 'UI designer / Dev web',
            duree: 'Avril - Octobre 2016',
            link: 'http://pays-du-vuache.fr/',
            outil: 'Notepad ++, Photoshop CC',
            language: 'HTML-CSS, Javascript, Wordpress',
            accueil: 'assets/img/realisation/projets/pdv/pdv.jpg',
            line: 'vertical',
            maquette:  [
                {   img: 'assets/img/realisation/projets/pdv/pdv-1.jpg',
                    name: "Page d'accueil"
                },
                {   img: 'assets/img/realisation/projets/pdv/pdv-2.jpg',
                name: "Galerie d'images"
                }
            ]
        },
        {
            id: 8,
            title: 'Ixarm',
            type: 'professionnel',
            description: "Conception & intégration graphique d’une refonte d’un site internet.",
            poste: 'UI designer / Inté. web',
            duree: 'Avril - Décembre 2016',
            link: 'https://www.ixarm.com',
            outil: 'Php Storm, Photoshop CC',
            language: 'HTML-CSS, SASS, Javascript, Bootstrap 3, Twig',
            accueil: 'assets/img/realisation/projets/ixarm/ixarm.jpg',
            line: 'vertical',
            maquette:  [
                {   img: 'assets/img/realisation/projets/ixarm/ixarm-1.jpg',
                    name: "Page d'accueil"
                },
                {   img: 'assets/img/realisation/projets/ixarm/ixarm-2.jpg',
                name: "Article"
                }
            ]
        },
        {
            id: 9,
            title: 'Ville de Lyon',
            type: 'professionnel',
            description: "Réponse d'un appel d'offre sur la refonte de leur site internet",
            poste: 'UI designer',
            duree: 'Novembre 2016',
            link: '',
            outil: 'Photoshop CC, Invision',
            accueil: 'assets/img/realisation/projets/lyon/lyon.jpg',
            line: 'vertical',
            maquette:  [
                {   img: 'assets/img/realisation/projets/lyon/lyon-1.jpg',
                    name: "Page d'accueil"
                },
                {   img: 'assets/img/realisation/projets/lyon/lyon-2.jpg',
                name: "Page catégorie"
                },
                {   img: 'assets/img/realisation/projets/lyon/lyon-3.jpg',
                name: "Mon espace"
                }
            ]
        },
        {
            id: 10,
            title: 'My collection',
            type: 'personnel',
            description: "Application web d’une vidéoludothèque personnelle. Elle permet de lister, trier et consulter les jeux selon différents filtres. L’application peut choisir un jeu aléatoirement en tenant compte des critères définis.",
            poste: 'UX-UI designer / Dev. front-end',
            duree: 'Janvier 2017',
            link: 'http://christo-artworks.net/mycollection/',
            outil: 'Visual Studio Code, Photoshop CC',
            language: 'HTML-CSS, SASS, Javascript, Bootstrap 4, Angular Js',
            accueil: 'assets/img/realisation/projets/mycollection/mycollection.jpg',
            line: 'horizontal',
            maquette:  [
                {   img: 'assets/img/realisation/projets/mycollection/mycollection-1.jpg',
                    name: "Page d'accueil"
                },
                {   img: 'assets/img/realisation/projets/mycollection/mycollection-2.jpg',
                name: "Listing de la collection (triée)"
                },
                {   img: 'assets/img/realisation/projets/mycollection/mycollection-3.jpg',
                name: "Fiche d'un jeu"
                },
                {   img: 'assets/img/realisation/projets/mycollection/mycollection-4.jpg',
                name: "Avis concernant un jeu"
                },
                {   img: 'assets/img/realisation/projets/mycollection/mycollection-5.jpg',
                name: "Module aléatoire"
                }
            ]
        },
        {
            id: 11,
            title: 'Amadeus',
            type: 'professionnel',
            description: "Refonte de portail de sites du service e-support du client.",
            poste: 'UX-UI designer / Inté. web',
            duree: 'Mars 2017',
            link: 'http://www.amadeus.com',
            outil: 'Visual Studio Code, Photoshop CC',
            language: 'HTML-CSS, SASS, Javascript, Bootstrap 3',
            accueil: 'assets/img/realisation/projets/amadeus/amadeus.jpg',
            maquette:  [
                {   img: 'assets/img/realisation/projets/amadeus/amadeus-1.jpg',
                    name: "Page de connexion"
                },
                {   img: 'assets/img/realisation/projets/amadeus/amadeus-2.jpg',
                name: "Page d'accueil global"
                },
                {   img: 'assets/img/realisation/projets/amadeus/amadeus-3.jpg',
                name: "Page d'accueil partie Airlines"
                },
                {   img: 'assets/img/realisation/projets/amadeus/amadeus-4.jpg',
                name: "Page d'accueil - version light"
                }
            ]
        },
        {
            id: 12,
            title: 'Pôle Emploi',
            type: 'professionnel',
            description: "Réponse d'un appel d'offre sur une plateforme de films virtuels (découverte des métiers)",
            poste: 'UI designer',
            duree: 'Janvier 2019',
            link: '',
            outil: 'Photoshop CC, Invision',
            accueil: 'assets/img/realisation/projets/pole/pole.jpg',
            line: 'vertical',
            maquette:  [
                {   img: 'assets/img/realisation/projets/pole/pole-1.jpg',
                    name: "Page d'accueil"
                },
                {   img: 'assets/img/realisation/projets/pole/pole-2.jpg',
                name: "Liste des vidéos"
                },
                {   img: 'assets/img/realisation/projets/pole/pole-3.jpg',
                name: "Fiche vidéo"
                }
            ]
        },
        {
            id: 13,
            title: 'Ccube',
            type: 'professionnel',
            description: "Conception graphique et ergonomique de l’application, intégration graphique et développement front d'une application web permettant une auto-évaluation des prises en charge médicales en hospitalisation pour les cliniciens",
            poste: 'UX-UI designer / Dev. front-end',
            duree: 'Avril-Juillet 2019',
            link: 'https://www.ccube.fr',
            language: 'HTML-CSS, SASS, Javascript, Material Angular, Angular 7',
            outil: 'Visual Studio Code, Photoshop CC, Invision',
            accueil: 'assets/img/realisation/projets/ccube/ccube.jpg',
            line: 'horizontal',
            maquette: [
                {
                    img: 'assets/img/realisation/projets/ccube/ccube-1.jpg',
                    name: "Page d'accueil"
                },
                {
                    img: 'assets/img/realisation/projets/ccube/ccube-2.jpg',
                    name: "Page de restitution"
                }
            ]
        },
        {
            id: 14,
            title: 'Show me Tableau Software',
            type: 'professionnel',
            description: "Vitrine présentant les diverses prestations de la société Keyrus sur l'outil Dataviz Tableau. Réalisation graphique du Mashup. ",
            poste: 'UX-UI designer /Inté. web',
            duree: 'Juillet-Aout 2019',
            link: 'http://showmetableausoftware.com/',
            language: 'HTML-CSS, SASS, Bootstrap 4',
            outil: 'Visual Studio Code, Photoshop CC, Invision',
            accueil: 'assets/img/realisation/projets/show/show.jpg',
            line: 'horizontal',
            maquette: [
                {
                    img: 'assets/img/realisation/projets/show/show-1.jpg',
                    name: "Page d'accueil de la vitrine"
                },
                {
                    img: 'assets/img/realisation/projets/show/show-2.jpg',
                    name: "Page de dashboard du mashup"
                },
            ]
        }
    ];

    emitRealisationSubject() {
        this.realisationSubject.next(this.realisations.slice().reverse());
    }

    getAppareilById (id: number) {
        const realisation = this.realisations.find(
            (appareilObject) => {
                return appareilObject.id === id;
            }
        );
        return realisation;
    }

}
