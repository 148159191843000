import { Component, OnInit} from '@angular/core';
import { RealisationService } from '../services/realisation.services';
import { Subscription } from 'rxjs';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { RouterModule, Router } from '@angular/router';
@Component({
  selector: 'app-realisation-view',
  templateUrl: './realisation-view.component.html',
  styleUrls: ['./realisation-view.component.scss']
})
export class RealisationViewComponent implements OnInit {
  link: string;
  parentLink: string[];
  isAuth = false;
  lastUpdate = new Promise((resolve, reject) => {
    const date = new Date();
    setTimeout(
      () => {
        resolve(date);
      }, 2000
    );
  });

  realisations: any [];
  realisationSubscription: Subscription;


  constructor(private realisationService: RealisationService, private router: Router ) {
    setTimeout(
      () => {
        this.isAuth = true;
      }, 4000
    );
  }

  ngOnInit () {
    this.realisationSubscription = this.realisationService.realisationSubject.subscribe(
      (realisations: any[]) => {
        this.realisations = realisations;
      }
    );
    this.realisationService.emitRealisationSubject();
  }

  getType() {
    this.link = this.router.url;
    this.parentLink = this.link.split("/", 3);
      return this.parentLink[2];
  }
}
