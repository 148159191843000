import { Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { RealisationService } from '../services/realisation.services';
import { ActivatedRoute } from '@angular/router';

import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { Lightbox } from 'ngx-lightbox';

@Component({
  selector: 'app-realisation-fiche',
  templateUrl: './realisation-fiche.component.html',
  styleUrls: ['./realisation-fiche.component.scss']
})
export class RealisationFicheComponent implements OnInit {
  fiche: any;

  constructor(private realisationService: RealisationService, private route: ActivatedRoute ) { }

  ngOnInit() {
    const id = this.route.snapshot.params['id'];
    this.fiche = this.realisationService.getAppareilById(+id);
  }

}
