import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';

import { ExperienceService } from './services/experience.services';
import { RealisationService } from './services/realisation.services';
import { CompetenceService } from './services/competence.services';
import { IllustrationService } from './services/illustration.services';

import { FourOhFourComponent } from './four-oh-four/four-oh-four.component';
import { AppHeaderComponent } from './app-header/app-header.component';
import { PresentationComponent } from './presentation/presentation.component';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { ExperienceViewComponent } from './experience-view/experience-view.component';
import { Base, inject } from 'angular-util';
import { ReactiveFormsModule } from '@angular/forms';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { SliderComponent } from './slider/slider.component';
import { RealisationViewComponent } from './realisation-view/realisation-view.component';
import { CompetenceViewComponent } from './competence-view/competence-view.component';
import { IllustrationViewComponent } from './illustration-view/illustration-view.component';
import { LightboxModule } from 'ngx-lightbox';
import { RealisationFicheComponent } from './realisation-fiche/realisation-fiche.component';
import { ContactViewComponent } from './contact-view/contact-view.component';
import { AppFooterComponent } from './app-footer/app-footer.component';



const appRoutes: Routes = [
  {path: 'experiences',  component: ExperienceViewComponent},
  {path: 'contact', component: ContactViewComponent},
  {path: 'competences', component: CompetenceViewComponent},
  {path: 'realisations/illustrations', component: IllustrationViewComponent},
  {path: 'realisations', component: RealisationViewComponent},
  {path: 'realisations/personnel', component: RealisationViewComponent},
  {path: 'realisations/personnel/:id', component: RealisationFicheComponent},
  {path: 'realisations/professionnel', component: RealisationViewComponent},
  {path: 'realisations/professionnel/:id', component: RealisationFicheComponent},
  {path: 'assets', component: PresentationComponent},
  {path: 'assets/**', component: PresentationComponent},
  {path: '', component: PresentationComponent},
  {path: '**', redirectTo: ''},
  {path: 'not-found', component: FourOhFourComponent},
];

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  useBothWheelAxes: true,
  wheelPropagation: true,
  suppressScrollY: true,
};

@NgModule({
  declarations: [
    AppComponent,
    FourOhFourComponent,
    AppHeaderComponent,
    PresentationComponent,
    ExperienceViewComponent,
    SliderComponent,
    RealisationViewComponent,
    CompetenceViewComponent,
    IllustrationViewComponent,
    RealisationFicheComponent,
    ContactViewComponent,
    AppFooterComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    RouterModule.forRoot(appRoutes),
    PerfectScrollbarModule,
    NgbModule,
    LightboxModule
  ],
  providers: [
    ExperienceService,
    RealisationService,
    CompetenceService,
    IllustrationService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
