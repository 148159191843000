import { Component, OnInit } from '@angular/core';
import { IllustrationService } from '../services/illustration.services';
import { Lightbox, LightboxConfig, LightboxEvent, LIGHTBOX_EVENT, IEvent, IAlbum } from 'ngx-lightbox';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-illustration-view',
  templateUrl: './illustration-view.component.html',
  styleUrls: ['./illustration-view.component.scss']
})
export class IllustrationViewComponent{

  public albums: Array<IAlbum>;
  private _subscription: Subscription;
  illustrations: any [];
  illustrationSubscription: Subscription;

  constructor(
    private _lightbox: Lightbox,
    private _lightboxEvent: LightboxEvent,
    private _lighboxConfig: LightboxConfig,
    private illustrationService: IllustrationService
  ) {
    this.albums = [];
    this.illustrationSubscription = this.illustrationService.illustrationSubject.subscribe(
      (illustrations: any[]) => {
        this.illustrations = illustrations;
      }
    );
    this.illustrationService.emitillustrationSubject();
    /*for (let i = 1; i <= 13; i++) {
      const src = 'assets/img/realisation/dessins/image' + i + '.jpg';
      const thumb = 'assets/img/realisation/dessins/image' + i + '.jpg';
      const caption = 'Image ' + i + ' caption here';
      const album = {
         src: src,
         caption: caption,
         thumb: thumb
      };

      this.albums.push(album);
    }*/
    for (const dessin of this.illustrations) {
      const src = 'assets/img/realisation/dessins/' + dessin.src;
      const thumb = 'assets/img/realisation/dessins/' + dessin.src;
      const caption = dessin.caption;
      const position = dessin.position;
      const album = {
         src: src,
         caption: caption,
         thumb: thumb,
         position: position
      };

      this.albums.push(album);
  }

    this.albums.reverse();
    // set default config
    this._lighboxConfig.fadeDuration = 1;
  }

  open(index: number): void {
    this._subscription = this._lightboxEvent.lightboxEvent$.subscribe((event: IEvent) => this._onReceivedEvent(event));

    // override the default config
    this._lightbox.open(this.albums, index, { wrapAround: true, showImageNumberLabel: false });
  }

  private _onReceivedEvent(event: IEvent): void {
    if (event.id === LIGHTBOX_EVENT.CLOSE) {
      this._subscription.unsubscribe();
    }
  }
}
