import { Component } from '@angular/core';
import { Subject, interval, Subscription } from 'rxjs';
import { NgModule } from '@angular/core';
import { RouterModule, Router } from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  counterSubscription: Subscription;
  link: string;
  parentLink: string[];
  constructor(private router: Router) {
  }

  getColor() {
    this.link = this.router.url;
    this.parentLink = this.link.split("/", 2);
    if (this.router.url === '/') {
      return '';
    } else if ( (this.parentLink[1] === 'realisations') || (this.parentLink[1] === 'appareils')) {
      return 'darkpurple';
    } else if (this.parentLink[1]  === 'experiences')  {
      return 'lightblue';
    } else if (this.parentLink[1]  === 'competences')  {
      return 'green';
    } else if (this.parentLink[1]  === 'contact')  {
      return 'orange';
    }
  }


  getfooter() {
    this.link = this.router.url;
    this.parentLink = this.link.split("/", 2);
    if (this.router.url === '/') {
      return '';
    } else if ( (this.parentLink[1] === 'realisations') || (this.parentLink[1] === 'appareils')) {
      return 'darkpurple';
    } else if (this.parentLink[1]  === 'experiences')  {
      return 'lightblue';
    } else if (this.parentLink[1]  === 'competences')  {
      return 'green';
    } else if (this.parentLink[1]  === 'contact')  {
      return 'orange';
    }
  }
}
