import { Component, OnInit } from '@angular/core';
import { ExperienceService } from '../services/experience.services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-experience-view',
  templateUrl: './experience-view.component.html',
  styleUrls: ['./experience-view.component.scss']
})
export class ExperienceViewComponent implements OnInit {

  experiences: any [];
  experienceSubscription: Subscription;

  constructor(private experienceService: ExperienceService ) {
  }

  ngOnInit () {
    this.experienceSubscription = this.experienceService.experienceSubject.subscribe(
      (experiences: any[]) => {
        this.experiences = experiences;
      }
    );
    this.experienceService.emitexperienceSubject();
  }
}
