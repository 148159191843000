import {Subject} from 'rxjs';


export class ExperienceService {

    experienceSubject = new Subject<any[]>();
    private experiences = [
        {
            id: 1,
            title: 'Keyrus',
            icon: 'keyrus.png',
            poste: "Ingénieur projets confirmé",
            duree: 'Avril 2019 - Actuellement',
            activite: 'SEN',
            description: "Intervention en intégration/développement front-end, ergonomie et conception graphique sur divers projets clients et outils dataviz en R&D.",
            taches: ["Developpement front end Angular (Composants - Routes - Directives - Services)", "Intégration graphique des applications web", "Former des juniors sur l'intégration graphique (Flexbox)", "Initier les équipes sur l'intégration graphique", "Conception de charte graphique (Atomic Design)", "Respect des normes RGAA", "Réalisation de maquettes fonctionnelles et graphiques", "Organisation d'atelier graphique"],
            clients: [
                {
                    nom: 'Ministère des Solidarités et de la Santé',
                    img: 'sante.png',
                    link: 'https://solidarites-sante.gouv.fr/'
                }
            ]
        },
        {
            id: 2,
            title: 'Astek',
            icon: 'astek.png',
            poste: "Ingénieur d'études et développement",
            duree: 'Avril 2017 - Mars 2019',
            activite: 'SEN',
            description: "Intervention en intégration graphique, ergonomie et conception graphique sur divers projets au forfait ou chez le client.",
            taches: ["Intégration graphique des applications web","Former les équipes sur l'intégration graphique","Pilotage des équipes sur l'intégration graphique","Conception de charte graphique (Atomic Design)","Respect des normes RGAA","Rédaction de personas","Réalisation de maquettes fonctionnelles et graphiques","Organisation d'atelier graphique"],
            clients: [
                {
                    nom: 'Jetpulp',
                    img: 'jetpulp.png',
                    link: 'https://www.jetpulp.fr/'
                },
                {
                    nom: "Association des Guides et Scouts d'Europe",
                    img: 'agse.png',
                    link: 'https://www.scouts-europe.org/'
                },
                {
                    nom: 'Bureau Veritas',
                    img: 'bureauveritas.png',
                    link: 'https://www.bureauveritas.fr/'
                },
                {
                    nom: 'Worldline',
                    img: 'worldline.png',
                    link: 'https://fr.worldline.com/'
                },
                {
                    nom: 'Spie',
                    img: 'spie.png',
                    link: 'http://www.spie.com/'
                }
            ]
        },
        {
            id: 3,
            title: 'Ausy',
            icon: 'ausy.png',
            poste: "Ingénieur",
            duree: 'Octobre 2015 - Mars 2017',
            activite: 'SEN',
            description: "Intervention en intégration graphique et conception graphique sur divers projets au forfait ou chez le client.",
            taches: ["Intégration graphique de sites web","Conception de charte graphique","Réalisation de maquettes graphiques","Respect des normes RGAA","Organisation d'atelier graphique","Participation aux RAO"],
            clients: [
                {
                    nom: 'Amadeus',
                    img: 'amadeus.png',
                    link: 'http://www.amadeus.com'
                },
                {
                    nom: 'European Broadcasting Union',
                    img: 'ebu.png',
                    link: 'https://www.ebu.ch/'
                },
                {
                    nom: "Direction Générale de l'Armement",
                    img: 'dga.png',
                    link: 'https://www.defense.gouv.fr/dga'
                },
                {
                    nom: 'GRTgaz',
                    img: 'grtgaz.png',
                    link: 'http://www.grtgaz.com/'
                }
            ]
        },
        {
            id: 4,
            title: 'Lise Charmel',
            icon: 'LC.png',
            poste: 'Alternant',
            duree: 'Novembre 2012 - Septembre 2015',
            activite: 'Industrie du textile',
            description: "Participation aux divers projets du groupe Lise Charmel : refonte du site institutionnel et création du site e-commerce",
            taches: ["Intégration graphique du site","Réalisation de maquettes graphiques","Rédaction de supports techniques","Maintenance du site","Rédaction de benchmarks", "Elaborer divers infographies d'analyse d'audience","Programmation de modules js (guide de tailles, amélioration de carrousel)"],
        }
    ];

    emitexperienceSubject() {
        this.experienceSubject.next(this.experiences.slice());
    }

    getexperienceById (id: number) {
        const experience = this.experiences.find(
            (experienceObject) => {
                return experienceObject.id === id;
            }
        );
        return experience;
    }

}
