import {Subject} from 'rxjs';


export class CompetenceService {

    competenceSubject = new Subject<any[]>();
    private competences = [
        {
            id: 0,
            part: 'technique',
            type: 'Front-end',
            skills: [
                {
                    nom: 'HTML-CSS',
                    note: 4,
                },
                {
                    nom: 'Sass',
                    note: 4,
                },
                {
                    nom: 'Bootstrap 4',
                    note: 4,
                },
                {
                    nom: 'Twig',
                    note: 3,
                },
                {
                    nom: 'Javascript',
                    note: 2,
                },
                {
                    nom: 'Angular 7',
                    note: 1,
                }
            ]
        },
        {
            id: 1,
            part: 'technique',
            type: 'Graphique',
            skills: [
                {
                    nom: 'Adobe Photoshop CC',
                    note: 4,
                },
                {
                    nom: 'Paint Tool Sai',
                    note: 4,
                },
                {
                    nom: 'InVision',
                    note: 3,
                },
                {
                    nom: 'Adobe Illustrator CC',
                    note: 2,
                },
                {
                    nom: 'Adobe XD',
                    note: 2,
                }
            ]
        },
        {
            id: 2,
            part: 'fonctionnelle',
            type: 'UX/UI Design',
            skills: [
                {
                    nom: 'Maquette graphique',
                    note: 4,
                },
                {
                    nom: 'Atomic design',
                    note: 3,
                },
                {
                    nom: 'RAO',
                    note: 3,
                },
                {
                    nom: 'Storyboard',
                    note: 3,
                },
                {
                    nom: 'Benchmarking',
                    note: 3,
                },
                {
                    nom: 'Maquette fonctionnelle',
                    note: 2,
                }
            ]
        },
        {
            id: 3,
            part: 'fonctionnelle',
            type: 'Gestion de projet',
            skills: [
                {
                    nom: 'Jira',
                    note: 3,
                },
                {
                    nom: 'Trello',
                    note: 3,
                },
                {
                    nom: 'Méthode agile',
                    note: 3,
                }
            ]
        }
    ];

    emitcompetenceSubject() {
        this.competenceSubject.next(this.competences.slice());
    }

    getcompetenceById (id: number) {
        const competence = this.competences.find(
            (competenceObject) => {
                return competenceObject.id === id;
            }
        );
        return competence;
    }

}
