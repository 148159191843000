import { Component, OnInit } from '@angular/core';
import { CompetenceService } from '../services/competence.services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-competence-view',
  templateUrl: './competence-view.component.html',
  styleUrls: ['./competence-view.component.scss']
})
export class CompetenceViewComponent implements OnInit {

  competences: any [];
  competenceSubscription: Subscription;
  test: number;

  constructor(private competenceService: CompetenceService ) {
  }

  ngOnInit () {
    this.competenceSubscription = this.competenceService.competenceSubject.subscribe(
      (competences: any[]) => {
        this.competences = competences;
      }
    );
    this.competenceService.emitcompetenceSubject();
  }
}
